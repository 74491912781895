.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 55vh;
  /* height: 100vh; */
  /* todo: change height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.logo-container {
  position: relative;
}

.logo {
  width: 110px; /* Adjust size as needed */
  height: auto;
  transition: opacity 0.5s ease-in-out;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px; /* Adjust size as needed */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.first-line,
.second-line {
  display: block;
  text-align: center;
}

.line {
  width: 100%;
  border-top: 2px solid black;
  margin: 10px 0;
}

.fade-out {
  opacity: 0.5; /* Adjust opacity as needed */
}

.fade-in {
  opacity: 1;
}


