.video-carousel-mobile-container {
  position: relative;
}

.video {
  width: 100%;
  height: auto;
}

.nav-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-arrows button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
}

.nav-arrows button:disabled {
  color: #666;
  cursor: not-allowed;
}
