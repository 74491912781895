.services {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
}

.service {
  margin-top: 20px; /* Add some spacing between services */
}

.centered-text {
  max-width: 1200px; /* Adjust this value as needed */
  margin: 0 auto; /* Center the text */
  padding: 0 20px; /* Optional: add some padding */
}
