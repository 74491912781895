/* .teams-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  } */
  
  .teams-container {
    width: 90%; /* Adjust the width of the container */
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }

  .heading {
    text-align: center;
    padding-top: 20px;
  }
  
  .team-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px; /* Spacing between rectangles */
  }
  
  .team-item {
    width: calc(33.33% - 10px); /* Adjust the width dynamically */
    margin-bottom: 20px;
  }
  
  .team-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F0F0F0;
    padding: 10px;
    border-radius: 8px;
  }
  
  .team-logo {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .team-name {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .team-item {
      width: calc(50% - 10px); /* Adjust the width for two teams per row */
    }
  }
  