 /* html, body {
    height: 100%; 
    margin: 0;
    padding: 0;
  }   */

  .video-carousel-container {
    position: relative;
    width: 65%; /* move around the size of the container that holds the video */
    height: auto; /* Set height to auto to fit the video's intrinsic dimensions */
    margin: 0 auto; /* Center the container */
    overflow: hidden;
    padding-top: 20px;
}

.video {
    width: 100%;
    height: 100%; /* Ensure the video fills the container */
    display: block;
    margin: 0 auto; /* Center the video */
    object-fit: contain; /* Maintain aspect ratio without cropping */
}

  
  .progress-container {
    position: absolute;
    bottom: 10px; /* Adjust this value to position the progress bars as needed */
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    padding: 0 20px;
  }
  
  .progress-bar {
    width: 18%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
    margin: 0 5px; /* Add margin here to create space between progress bars */
  }
  
  .progress {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    width: 0;
  }
  