.video-player-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .video-player {
    width: 100%;
    max-width: 800px;
    height: auto;
    border: 2px solid #000;
  }
  