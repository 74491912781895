.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    width: 100%;
    background-color: transparent; /* Set background to transparent */
  }
  
  .services {
    width: 100%;
  }
  